import { CaseAggregationStatistics, CaseStatistics } from "./Case";
import { BomNode, Edge, NodeActivitySchema, NodeActivityValueSchema, NodeKpiSchema, NodeObject, NodeRoles, NodeStats } from "./Dfg";
import { EventFilter } from "./EventFilter";
import { EventKeys } from "./EventKeys";
import { BottleneckFactorWeights, UploadsType } from "./Project";
import { Stats } from "./Stats";

export type GanttEntry = {
    activity: string;
    activityValues: NodeActivitySchema;
    /**
     * Tuple of start, end and value(s) of a bar in the gantt chart
     */
    events: [number | string, number | string, number | number[]][]
}

export enum TimeMode {
    Absolute = "absolute",
    CaseRelative = "caseRelative",
    WeekRelative = "weekRelative"
}

export enum ActivitySortMode {
    Occurence = "occurence",
    Alphabetical = "alphabetical"
}

export type CaseGanttData = {
    cases: CaseGanttCase[]
    activities: string[]
}

export type CaseGanttCase = {
    events: [number | string, number | string, number | number[]][];
    caseId: string;
    caseDuration: number;
    caseYieldCount?: number;
    caseYieldRateCount?: number;
    caseYieldMass?: number;
    caseYieldRateMass?: number;
    caseYieldLength?: number;
    caseYieldRateLength?: number;

}

export type GanttStatistics = {
    minTime: number,
    maxTime: number,
    minCount: number,
    maxCount: number,
    minInterval: number,
    maxInterval: number
};


export enum CaseGanttSetting {
    Duration = "duration",
    Yield = "yield",
    YieldRate = "yieldRate",
    StartTime = "startTime",
}

export type ApiErrorContextType = {
    isFaulty: boolean,
    isRetryable?: boolean,
    error?: ApiError,
}

export type ApiPaginationOptions = {
    limit?: number;
    offset?: number;
    sort?: string[];
};

export type SetupMatrixTypes = "goodToGood" | "endToStart" | "setupTime";

export type SetupMatrixParams = TraceOptions & {
    machines: string[];
    setupMatrixType: SetupMatrixTypes;
}

export type SetupMatrixElementSchema = {
    from: string;
    to: string;
    timeStatistics: Stats;
    frequencyStatistics: Stats;
};

export type NodeActivityMatrixSchema = {
    timeComponent: NodeActivityValueSchema;
    operation: NodeActivityValueSchema;
    machine: NodeActivityValueSchema;
    machineType: NodeActivityValueSchema;
    location: NodeActivityValueSchema;
    product: NodeActivityValueSchema;
    passId: NodeActivityValueSchema;
    objectType: NodeActivityValueSchema;
}

export type NodeMatrixSchema = {
    id: string;
    activityValues: NodeActivityMatrixSchema;
};

export type SetupMatrixSchema = {
    transitions: SetupMatrixElementSchema[];
    nodes: NodeMatrixSchema[];
}

export type EquipmentStatisticsSchema = {
    equipment: EquipmentNodeStatisticsSchema[];
    planned: {
        equipment: EquipmentNodeStatisticsSchema[];
    }
}

export type EquipmentNodeStatisticsSchema = {
    id?: string;
    activityValues?: NodeActivitySchema;
    timeStatistics?: Stats;
    setupTimeStatistics?: Stats;
    failureTimeStatistics?: Stats;
    interruptionTimeStatistics?: Stats;
    productionTimeStatistics?: Stats;
    busyTimeStatistics?: Stats;
    unknownTimeStatistics?: Stats;
    pureObjectTimeStatistics?: Stats;
    customKpis?: CustomKpi[];
}

export type HistogramNumericParams = TraceOptions & {
    /** Number of bins to use when constructing the histogram. */
    nBins?: number;

    /** Exact boundaries of bins to use for histogram. If this is provided it will override nBins. */
    binsToUse?: number[];

    /** Key of case statistic or case attribute for histogram. */
    statistic: string;
};

export type TimedeltaHistogramSchema = {
    /**
     * List of cuts that are made in the data (values are represented in
     * seconds). The length of this list will be one more than the number of
     * entries.
     */
    bins: number[];

    /**
     * Number of entries within each bin.
     */
    counts: number[];
};

export type CaseDeviationStatisticsParams = ApiPaginationOptions & SubTimeOptions & {
    consolidatePasses?: boolean;
    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;
    filterCaseDurationDeviations?: "early" | "late";
    planned: Omit<TraceOptions, "eventFilters">,
    actual: TraceOptions;
}

export enum TimePeriodFrequencies {
    Hour = "hour",
    Day = "day",
    Week = "week",
    Month = "month",
    Year = "year",
}

export const timePeriodFrequenciesArray: TimePeriodFrequencies[] = Object.values(TimePeriodFrequencies);

export type PerTimeperiodStatisticsParams = TraceOptions & SubTimeOptions & ApiPaginationOptions & {
    consolidatePasses?: boolean;
    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;
    calculateUnknownStats?: boolean;
    calculateBusyStats?: boolean;
    calculatePassStats?: boolean;
    calculateTimeAndFreqStats?: boolean;
    calculatePlanned?: boolean;
    frequency?: TimePeriodFrequencies;
    aggs?: AggTypes[];
    tz?: string;
};

export type PerTimeperiodDeviationStatisticsParams = SubTimeOptions & ApiPaginationOptions & {
    consolidatePasses?: boolean;
    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;
    calculateUnknownStats?: boolean;
    calculateBusyStats?: boolean;
    calculatePassStats?: boolean;
    calculateTimeAndFreqStats?: boolean;
    customKpis?: CustomKpi[];
    frequency?: TimePeriodFrequencies;
    tz?: string;
    filterCaseDurationDeviations?: "early" | "late";
    actual: TraceOptions;
    planned: Omit<TraceOptions, "eventFilters">;
}

export type TimeperiodDeviationStatisticsSchema = {
    timeperiodStartTime: string;
    caseCount: number;
    actual: CaseAggregationStatistics;
    planned?: CaseAggregationStatistics;
    deviation?: CaseAggregationDeviationsSubStatisticsSchema;
}

export type PerTimeperiodDeviationStatisticsSchema = {
    timeperiods: TimeperiodDeviationStatisticsSchema[];
    frequency: TimePeriodFrequencies;
    log: {
        timeperiodCount: number;
    };
}

export type TimeperiodCaseAggregationStatisticsSchema = {
    count: number;
    durationStatistics?: Stats;
    caseYieldMassStatistics?: Stats;
    caseYieldLengthStatistics?: Stats;
    caseYieldCountStatistics?: Stats;
    caseScrapMassStatistics?: Stats;
    caseScrapLengthStatistics?: Stats;
    caseScrapCountStatistics?: Stats;
    caseOutputMassStatistics?: Stats;
    caseOutputLengthStatistics?: Stats;
    caseOutputCountStatistics?: Stats;
    caseElectricityEnergyStatistics?: Stats;
    caseGasEnergyStatistics?: Stats;
    caseCarbonMassStatistics?: Stats;
    caseFailureFreqStatistics?: Stats;
    caseFailureTimeStatistics?: Stats;
    caseInterruptionFrequencyStatistics?: Stats;
    caseInterruptionTimeStatistics?: Stats;
    caseProductionFrequencyStatistics?: Stats;
    caseProductionTimeStatistics?: Stats;
    caseBusyFrequencyStatistics?: Stats;
    caseBusyTimeStatistics?: Stats;
    caseUnknownFrequencyStatistics?: Stats;
    caseUnknownTimeStatistics?: Stats;
    caseSetupFrequencyStatistics?: Stats;
    caseSetupTimeStatistics?: Stats;
    casePassChangeFrequencyStatistics?: Stats;
    casePassChangeTimeStatistics?: Stats;
    caseFrequencyStatistics?: Stats;
    caseTimeStatistics?: Stats;
    caseTechnicalLossesTimeStatistics?: Stats;
    caseOrganizationalLossesTimeStatistics?: Stats;
    caseQualityLossesTimeStatistics?: Stats;
    caseProcessLossesTimeStatistics?: Stats;
    caseYieldRateMassStatistics?: Stats;
    caseYieldRateLengthStatistics?: Stats;
    caseYieldRateCountStatistics?: Stats;
    relativeCaseYieldMassStatistics?: Stats;
    relativeCaseYieldLengthStatistics?: Stats;
    relativeCaseYieldCountStatistics?: Stats;
    relativeCaseScrapMassStatistics?: Stats;
    relativeCaseScrapLengthStatistics?: Stats;
    relativeCaseScrapCountStatistics?: Stats;
    caseElectricityEnergyPerCaseYieldMassStatistics?: Stats;
    caseElectricityEnergyPerCaseYieldLengthStatistics?: Stats;
    caseElectricityEnergyPerCaseYieldCountStatistics?: Stats;
    caseGasEnergyPerCaseYieldMassStatistics?: Stats;
    caseGasEnergyPerCaseYieldLengthStatistics?: Stats;
    caseGasEnergyPerCaseYieldCountStatistics?: Stats;
    caseCarbonMassPerCaseYieldMassStatistics?: Stats;
    caseCarbonMassPerCaseYieldLengthStatistics?: Stats;
    caseCarbonMassPerCaseYieldCountStatistics?: Stats;
    inverseRelativeCaseProductionTimeStatistics?: Stats;
    relativeCaseFailureTimeStatistics?: Stats;
    relativeCaseInterruptionTimeStatistics?: Stats;
    relativeCaseProductionTimeStatistics?: Stats;
    relativeCaseBusyTimeStatistics?: Stats;
    relativeCaseSetupTimeStatistics?: Stats;
    relativeCaseUnknownTimeStatistics?: Stats;
    relativeCaseTimeStatistics?: Stats;

    timeperiodStartTime: string;
}

export type PerTimeperiodStatisticsSchema = {
    frequency: TimePeriodFrequencies;
    timeperiods: TimeperiodCaseAggregationStatisticsSchema[];
}

export type PerProductStatisticsParams = TraceOptions & SubTimeOptions & ApiPaginationOptions & {
    consolidatePasses?: boolean;
    calculateOutputStats?: boolean;
    calculateTimeAndFreqStats?: boolean;
    calculateDeviations?: boolean;
    calculatePlanned?: boolean;
};


export type ProductCaseAggregationStatistics = CaseAggregationStatistics & {
    name: string;
    id: string;
    categories?: { [key: string]: string };
    customKpis?: {
        [key: string]: {
            value: number;
        }
    };
};

export type PerProductCaseStatisticsSchema = {
    products: ProductCaseAggregationStatistics[];
    log: {
        productCount: number;
    };
};

export type StatsCalculationResponse = {
    timeStatistics?: Stats;
    frequencyStatistics?: Stats;
    setupTimeStatistics?: Stats;
    setupFrequencyStatistics?: Stats;
    failureTimeStatistics?: Stats;
    failureFrequencyStatistics?: Stats;
    interruptionTimeStatistics?: Stats;
    interruptionFrequencyStatistics?: Stats;
    productionTimeStatistics?: Stats;
    productionFrequencyStatistics?: Stats;

    scrapCountStatistics?: Stats;
    scrapLengthStatistics?: Stats;
    scrapMassStatistics?: Stats;

    yieldCountStatistics?: Stats;
    yieldLengthStatistics?: Stats;
    yieldMassStatistics?: Stats;
    kpis?: NodeKpiSchema;
};

export type CustomKpi = {
    id: string,
    definition: string,
    statistics?: { aggs?: AggTypes[] },
    target: "graph.nodes" | "graph.edges" | "products" | "timeperiods" | "cases" | "equipment" | "nodes.timeperiods" | "edges.timeperiods" | "equipment.timeperiods",
};

export type StatsCalculationRequest = SubTimeOptions & {
    calculateTimeAndFreqStats?: boolean,
    calculateOutputStats?: boolean,
    calculateEnergyStats?: boolean,
    calculateCaseStats?: boolean,
    calculateScores?: boolean,
    calculateRoles?: boolean,
    calculateNodes?: boolean,
    calculateEdges?: boolean,
    calculateEquipment?: boolean,
    calculateGraphLogStats?: boolean,
    calculateActivityValues?: boolean,
    calculateNetEdgeTimes?: boolean,
    calculateNetPassTimes?: boolean,
    calculateDeviations?: boolean,
    calculatePlanned?: boolean,
    calculateKpis?: boolean
    consolidatePasses?: boolean,
    useActivityPasses?: boolean,
    customKpis?: CustomKpi[]
}

export type DfgRequest = TraceOptions & StatsCalculationRequest & {
    calculatePlanned?: boolean,
    calculateDeviations?: boolean,
    calculateNetEdgeTimes?: boolean,
}

export type CategoryStatisticsRequest = TraceOptions & StatsCalculationRequest & {
    categories: string[];
}

export type CategoryStatisticsData = (StatsCalculationResponse & {
    name: string;
    categories: { [key: string]: string[] },
    products: string[],
    [key: string]: Stats | string | string[] | { [key: string]: string[] } | undefined;
});

export type CategoryData = {
    name: string;
    statistics: CategoryStatisticsData[]
};

export type CategoryStatisticsResponse = {
    categories: CategoryData[];
};

export type ProductStatisticsRequest = TraceOptions & StatsCalculationRequest;

export type ProductStatisticsResponse = StatsCalculationResponse & {
    id: string,
    name: string,
    categories: { [columnName: string]: string },
    [key: string]: Stats | string | { [columnName: string]: string } | undefined;
};

export type ProductsStatisticsResponse = {
    products: ProductStatisticsResponse[]
};
export type ApiError = {
    detail: {
        loc: (string | number)[];
        msg: string;
        type: string;
    }[];
};

type DeviationPartParams = {
    uploadId: string;
    eventKeys: EventKeys;
};

export type DeviationOptions = SubTimeOptions & ApiPaginationOptions & {
    /**
     * If set to true and consolidate_passes is set to true, events within the same activity sequence
     * (activity pass) are grouped together. For basic time a event operations, the pass events act
     * as a single virtually lengthened event that spans all span events.
     */
    useActivityPasses?: boolean;

    /**
     * If set to false, skip kpi calculation.
     */
    calculateKpis?: boolean;

    /**
     * If set to true, add kpis that can be used to calculate equipment usage statistics
     * for all nodes as well. These statistics are based on the equipment usage during
     * the time range of the selected process, regardless of the cases or events that
     * are currently filtered.
     */
    calculateEquipment?: boolean;

    /**
     * If set to false, skip calculation of all graph dependant log statistics.
     */
    calculateGraphLogStats?: boolean;

    /**
     * Calculate activity key values for each group.
     */
    calculateActivityValues?: boolean;

    /**
     * If set to false, skip statistic calculations of events with pure object type.
     */
    calculatePureObjectStats?: boolean;

    /**
     * If set to false, don't calculate roles for activities.
     */
    calculateRoles?: boolean;

    /**
     * If set to false, skip calculation of all kind of output statistics.
     */
    calculateOutputStats?: boolean;

    /**
     * If set to false, skip calculation of all kind of energy and emission statistics.
     */
    calculateEnergyStats?: boolean;

    /**
     * If set to true, calculate relevance scores for edges and nodes. These can be used
     * to dynamically filter the graph based on more and less important nodes and edges.
     */
    calculateScores?: boolean;

    /**
     * If set to false, skip calculation of all kind of time statistics.
     */
    calculateTimeAndFreqStats?: boolean;

    /**
     * If set to false, skip calculation of all node statistics.
     */
    calculateNodes?: boolean;

    /**
     * If set to false, skip calculation of all node statistics.
     */
    calculateEdges?: boolean;

    /**
     * Consider only case duration deviations that are early/late depending on the setting.
     */
    filterCaseDurationDeviations?: "late" | "early" | undefined;

}

export type GetDeviationRequest = DeviationOptions & {
    planned: DeviationPartParams;
    actual: DeviationPartParams & {
        eventFilters: EventFilter[];
        uploads?: UploadsType;
    };
    consolidatePasses: boolean;
}

export type PerProductDeviationStatisticsSchema = {
    products: ProductDeviationStatisticsSchema[];
    log: {
        productCount: number;
    }
};

export type ProductDeviationStatisticsSchema = {
    /**
     * Unique product ID (computed)
     */
    id: string;

    /**
     * Product name as defined in the 'product' column of the event log.
     */
    name: string;

    categories: { [key: string]: string };

    actual: CaseAggregationStatistics;
    planned: CaseAggregationStatistics;
    deviation: CaseAggregationDeviationsSubStatisticsSchema;

    /**
     * Number of cases that contain the current product.
     */
    caseCount: number;
};

export type CaseAggregationDeviationsSubStatisticsSchema = CaseAggregationStatistics & {
    relativeToPlanned: CaseAggregationStatistics;
};

export type GetCaseDeviationResult = {
    cases: CaseDeviationStatisticsSchema[];
    log: {
        actual?: CaseAggregationStatistics;
        planned?: CaseAggregationStatistics;
        deviation?: CaseAggregationDeviationsSubStatisticsSchema;
        count?: number;
    }
};

export type CaseDeviationStatisticsSchema = {
    id: string;
    planned?: CaseStatistics;
    actual?: CaseStatistics;
    deviation?: CaseStatistics;
};

export type ColumnValue = {
    column: string,
    value: string
};

export type NodeValues = {
    /**
     * Node ID
     */
    activity: string,
    location?: string,
    machine?: string,
    machineType?: string,
    objectType?: string,
    operation?: string,
    passId?: string,
    timeComponent?: string,
};

export type RcaReasonsType = {
    name: string,
    columnValue?: ColumnValue,
    node?: string,
    nodeValues?: NodeValues,
}

export type RcaReasonsTypeEx = RcaReasonsType & {
    smallValues?: boolean,
}

export type EdgeDurationType = RcaReasonsTypeEx & {
    edge: {
        fromNode: NodeValues;
        toNode: NodeValues;
    }
}

export type EdgeOccurrenceType = EdgeDurationType;

export type NodeDurationType = RcaReasonsType;

export type FeatureType = {
    nodeOccurrence?: RcaReasonsType | null,
    caseAttribute?: RcaReasonsType | null,

    timeUsage?: RcaReasonsTypeEx | null,
    absoluteTimeUsage?: RcaReasonsTypeEx | null,
    frequencyUsage?: RcaReasonsTypeEx | null,

    failureReasonsFrequency?: RcaReasonsTypeEx | null,
    failureReasonsDuration?: RcaReasonsTypeEx | null,
    interruptionReasonsFrequency?: RcaReasonsTypeEx | null,
    setupReasonsFrequency?: RcaReasonsTypeEx | null,
    passChangeReasonsFrequency?: RcaReasonsTypeEx | null,
    passChangeReasonsDuration?: RcaReasonsTypeEx | null,
    interruptionReasonsDuration?: RcaReasonsTypeEx | null,
    organizationalLossesDuration?: RcaReasonsTypeEx | null,
    setupReasonsDuration?: RcaReasonsTypeEx | null,

    edgeDuration?: EdgeDurationType | null;
    edgeOccurrence?: EdgeOccurrenceType | null;
    edgeDurationDeviation?: EdgeDurationType | null;

    nodeDuration?: NodeDurationType | null;
    nodePassTime?: NodeDurationType | null;
    nodeSetupTime?: NodeDurationType | null;
    nodeFailureTime?: NodeDurationType | null;
    nodeInterruptionTime?: NodeDurationType | null;
    nodePassChangeTime?: NodeDurationType | null;
    nodeDurationDeviation?: NodeDurationType | null;
    nodeProductionTime?: NodeDurationType | null;
    nodeBusyTime?: NodeDurationType | null;
    nodeCycleTimeMass?: NodeDurationType | null;
    nodeCycleTimeLength?: NodeDurationType | null;
    nodeCycleTimeCount?: NodeDurationType | null;
    nodeAverageYieldStockMass?: NodeDurationType | null;
    nodeAverageYieldStockLength?: NodeDurationType | null;
    nodeAverageYieldStockCount?: NodeDurationType | null;
    nodeDiffYieldRateMass?: NodeDurationType | null;
    nodeDiffYieldRateLength?: NodeDurationType | null;
    nodeDiffYieldRateCount?: NodeDurationType | null;
    nodeStockRange?: NodeDurationType | null;
    nodeDiffStockRange?: NodeDurationType | null;
    nodeAvailability?: NodeDurationType | null;
    nodeUtilizationRate?: NodeDurationType | null;
    nodeBusyRatio?: NodeDurationType | null;

    interruptionReasonsDurationPerMachine?: RcaReasonsType | null;
    setupReasonsDurationPerMachine?: RcaReasonsType | null;
    passChangeReasonsDurationPerMachine?: RcaReasonsType | null;
    failureReasonsDurationPerMachine?: RcaReasonsType | null;
    maintenanceReasonsDurationPerMachine?: RcaReasonsType | null;

    interruptionReasonsFrequencyPerMachine?: RcaReasonsType | null;
    setupReasonsFrequencyPerMachine?: RcaReasonsType | null;
    passChangeReasonsFrequencyPerMachine?: RcaReasonsType | null;
    failureReasonsFrequencyPerMachine?: RcaReasonsType | null;
    maintenanceReasonsFrequencyPerMachine?: RcaReasonsType | null;

    [id: string]: EdgeDurationType | RcaReasonsType | RcaReasonsTypeEx | undefined | null,
}

export type FeatureImportance = {
    feature: FeatureType,
    /** @deprecated use `relevance` */
    meanAbsRelevance?: number
    relevance: number
}

export type RcatypeTargets = "kpis.relativeScrapMass" | "kpis.relativeScrapLength" | "kpis.relativeScrapCount" |
    "caseScrapMass" | "caseScrapLength" | "caseScrapCount" |
    "deviation.caseScrapMass" | "deviation.caseScrapLength" | "deviation.caseScrapCount" |
    "kpis.relativeCaseScrapMass" | "kpis.relativeCaseScrapLength" | "kpis.relativeCaseScrapCount" |
    "deviation.kpis.relativeCaseScrapMass" | "deviation.kpis.relativeCaseScrapLength" | "deviation.kpis.relativeCaseScrapCount" |
    "scrapMassStatistics.sum" | "scrapLengthStatistics.sum" | "scrapCountStatistics.sum" |
    "duration" | "kpis.caseYieldRateMass" | "kpis.caseYieldRateLength" |
    "kpis.caseYieldRateCount" | "deviation.duration";


export type ReasonDrilldownSchema = {
    subtime: SubReasonType | undefined;
    reason: string | undefined;
}


export type RcaDrilldownType = {
    node?: string | undefined;
    reason?: ReasonDrilldownSchema | undefined;
}

export type RcaRequestType = {
    modelType: "xgbclassifier" | "xgbregressor" | "linearregressor" | "miscore" | "bottleneckscore" | "orglosses";
    targetType: RcatypeTargets;
    drilldown?: RcaDrilldownType,
    options?: {
        maximize?: boolean;
        targetCaseIds?: string[];
        minTargets?: number;
        minCases?: number;
        targetPercentile?: number;
        bottleneckFactorWeights?: BottleneckFactorWeights;
        bottleneckIntermediateResults?: boolean | undefined;
        shapAggregation?: "mean" | "meanAbs";
        shapWeighting?: "linear" | "positiveOnly";
        featureSourceOverrides?: FeatureSourceOverrides;
    }
};

export enum RcaEventTypes {
    Production = "production",
    Setup = "setup",
    Failure = "failure",
    Interruption = "interruption",
    PassChange = "pass_change"
}

export enum SubReasonType {
    Setup = "setup",
    Failure = "failure",
    Interruption = "interruption",
    PassChange = "pass_change",
    OrganizationalLosses = "organizational_losses"
}

export type FeatureRequestSchema = {
    caseAttribute?: {
        name: string;
    },
    frequencyUsage?: {
        events: RcaEventTypes[];
    },
    absoluteTimeUsage?: {
        times: RcaEventTypes[];
    },
    frequencyReasons?: {
        reasons: SubReasonType[];
    },
    durationReasons?: {
        reasons: SubReasonType[];
    },
    edgeStatistics?: "time_statistics.sum" | "frequency_statistics.sum" | "occurrence"
    | "deviations.time_statistics.sum",
    nodeStatistics?: "time_statistics.sum" | "frequency_statistics.sum" | "setup_time_statistics.sum"
    | "setup_frequency_statistics.sum" | "failure_time_statistics.sum" | "occurrence"
    | "failure_frequency_statistics.sum" | "interruption_time_statistics.sum"
    | "interruption_frequency_statistics.sum" | "pass_change_time_statistics.sum"
    | "pass_change_frequency_statistics.sum" | "busy_time_statistics.sum" | "kpis.yield_rate_mass" | "kpis.yield_rate_length"
    | "kpis.yield_rate_count" | "deviations.time_statistics.sum" | "scrap_mass_statistics.sum"
    | "scrap_length_statistics.sum" | "scrap_count_statistics.sum"
    | "kpis.relative_scrap_mass" | "kpis.relative_scrap_length" | "kpis.relative_scrap_count"
    | "production_time_statistics.sum" | "production_frequency_statistics.sum"
    | "kpis.cycle_time_mass" | "kpis.cycle_time_length" | "kpis.cycle_time_count"
    | "kpis.diff_average_yield_stock_mass" | "kpis.diff_average_yield_stock_length"
    | "kpis.diff_average_yield_stock_count" | "kpis.diff_yield_rate_mass" | "kpis.diff_yield_rate_length"
    | "kpis.diff_yield_rate_count",
    reasonDurationPerNode?: {
        reason: string;
        subtime: SubReasonType;
    },
    reasonFrequencyPerNode?: {
        reason: string;
        subtime: SubReasonType;
    }
};

export type FeatureSourceOverride = {
  path: string;
}


export type FeatureSourceOverrides = {
    nodeCycleTimeMass?: FeatureSourceOverride
    nodeCycleTimeLength?: FeatureSourceOverride
    nodeCycleTimeCount?: FeatureSourceOverride
    nodeAvailability?: FeatureSourceOverride
    nodeUtilizationRate?: FeatureSourceOverride
}

export type PostRootCauseAnalysisRequest = {
    eventsParams: {
        eventlogs: {
            planned: DeviationPartParams | undefined,
            actual: DeviationPartParams & {
                eventFilters: EventFilter[];
                uploads?: UploadsType;
            }
        }
    },
    name: string,
    rcaType: RcaRequestType;
    timeLimit?: number;
}

export type PostRootCauseAnalysisResponse = {
    resultsId: string
}

export type RcaStatistics = {
    accuracyScore: number | null;
    f1Score: number | null;
    nCases: number | null;
    nFeatures: number | null;
    nTarget: number | null;
    percentTarget: number | null;
    precisionScore: number | null;
    recallScore: number | null;
    explainedVariance: number | null;
    meanAbsoluteError: number | null;
    meanAbsolutePercentageError: number | null;
};

export type GetRootCauseAnalysisResponse = {
    status: "started" | "failed" | "finished",
    error?: {
        msg: string,
        statusCode: number,
        type: string,
    },
    featureImportance: FeatureImportance[] | undefined;
    statistics?: RcaStatistics;
    request: PostRootCauseAnalysisRequest;
}

export type GetUploadRawEventsRequest = { id: string, limit?: number, offset?: number };

export type GetUploadsRequest = {
    offset?: number | undefined,
    limit?: number | undefined,
    sort?: "id" | "created" | "uploaded" | "userId" | "filename" | "filenameExtension" | "size" | "nEvents" | "meta" | "-id" | "-created" | "-uploaded" | "-userId" | "-filename" | "-filenameExtension" | "-size" | "-nEvents" | "-meta" | undefined,
    fields?: ("id" | "created" | "uploaded" | "userId" | "filename" | "filenameExtension" | "size" | "nEvents" | "meta" | "-id")[] | undefined,
    idEq?: string[] | undefined,
    createdLt?: string | Date | undefined,
    createdLe?: string | Date | undefined,
    createdGt?: string | Date | undefined,
    createdGe?: string | Date | undefined,
    updatedLt?: string | Date | undefined,
    updatedLe?: string | Date | undefined,
    updatedGt?: string | Date | undefined,
    updatedGe?: string | Date | undefined,
    sizeLt?: number | undefined,
    sizeLe?: number | undefined,
    sizeGt?: number | undefined,
    sizeGe?: number | undefined,
    filenameExtensionEq?: string | undefined
};

export type GetCaseStatisticsBlockResponse = {
    cases: CaseStatistics[];
    log?: CaseAggregationStatistics
};

export type GetCaseStatisticsResponse = GetCaseStatisticsBlockResponse & {
    planned?: GetCaseStatisticsBlockResponse;
    deviation?: GetCaseStatisticsBlockResponse;
};

export type GetProjectVariantsRequest = TraceOptions & {
    limit?: number,
    offset: number,
    /**
     * "count" | "-count" | "duration" | "-duration", "caseYieldLengthStatistics.mean",
     */
    sort?: string;
    consolidatePasses: boolean,
};

export type GetDistinctUploadAttributeValuesRequest = {
    uploadId: string;
    attributes: string[];
    maxValues: number;
}

export type GetDistinctUploadAttributeValuesResponse = {
    name: string;
    values: ColumnInfoUpload[];
}[];

export type GetNodesByCasesResponse = {
    records: NodeStats & {
        caseId: string;
        activity?: string;
        role?: NodeRoles;
        activityValues?: NodeActivitySchema;
    }[]
}

export type HistogramTraceOptions = TraceOptions & { nBins?: number, binsToUse?: string[] | number[] };

export type CaseStatisticsTraceOptions = TraceOptions & SubTimeOptions & ApiPaginationOptions & {
    calculateTimeAndFreqStats?: boolean,
    calculateOutputStats?: boolean,
    calculatePlanned?: boolean,
    calculateDeviations?: boolean,
    customKpis?: CustomKpi[],
}

export type GetDistinctAttributeValuesRequest = TraceOptions & { attributes: string[], limit?: number, combinations?: boolean };

export type CaseGanttChartRequestType = TraceOptions & { timeMode?: TimeMode, sortMode?: ActivitySortMode, limit?: number, sort?: string };

export type GanttChartRequestType = TraceOptions & { timeMode: TimeMode, timezone?: string, limit?: number };

export type PreviewStatisticsResponse = PreviewStatistics & {
    startTime?: Date;
    endTime?: Date;
};

export type DistinctAttributeValueResponse = {
    name: string;
    values: ColumnInfo[];
}[];

export type ColumnInfoUpload = {
    value: string;
    count: number;
    id: string;
};

export type ColumnInfo = {
    value: string | string[];
    count: number;
    id: string;
};

export type ColumnInfoNg = {
    value: (string | undefined)[],
    id: string,
};

export type TraceOptions = {
    uploadId: string;
    eventKeys: EventKeys;
    eventFilters: EventFilter[];
    uploads?: UploadsType;
}

export type SubTimeOptions = {
    calculateProductionStats?: boolean;
    calculateFailureStats?: boolean;
    calculateSetupStats?: boolean;
    calculateInterruptionStats?: boolean;
    calculatePassChangeStats?: boolean;
    calculateUnknownStats?: boolean;
    calculateBusyStats?: boolean;
    calculatePassStats?: boolean;
    calculateTimeAndFreqStats?: boolean;
    calculateMaintenanceStats?: boolean;
    customKpis?: CustomKpi[];
}

export type CalculateOptions = SubTimeOptions & {
    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;
    calculatePureObjectStats?: boolean;
    calculateProductionStats?: boolean;
}

export const enableAllCalcOptions = {
    calculatePlanned: true,
    calculateNetEdgeTimes: true,
    calculateDeviations: true,
    calculateRoles: true,
    calculateScores: true,
    calculateKpis: true,
    calculateActivityValues: true,
    calculateNodes: true,
    calculateEdges: true,
    calculateEquipment: true,
    calculateGraphLogStats: true,
    calculatePureObjectStats: true,
    calculateMaintenanceStats: true,
    calculateOutputStats: true,
    calculateEnergyStats: true,
    calculateUnknownStats: true,
    calculateProductionStats: true,
    calculateFailureStats: true,
    calculateSetupStats: true,
    calculateInterruptionStats: true,
    calculatePassChangeStats: true,
    calculateBusyStats: true,
    calculatePassStats: true,
    calculateTimeAndFreqStats: true,
};


export const disableAllCalcOptions = {
    calculatePlanned: false,
    calculateNetEdgeTimes: false,
    calculateDeviations: false,
    calculateRoles: false,
    calculateScores: false,
    calculateKpis: false,
    calculateActivityValues: false,
    calculateNodes: false,
    calculateEdges: false,
    calculateEquipment: false,
    calculateGraphLogStats: false,
    calculatePureObjectStats: false,
    calculateMaintenanceStats: false,
    calculateOutputStats: false,
    calculateEnergyStats: false,
    calculateUnknownStats: false,
    calculateProductionStats: false,
    calculateFailureStats: false,
    calculateSetupStats: false,
    calculateInterruptionStats: false,
    calculatePassChangeStats: false,
    calculateBusyStats: false,
    calculatePassStats: false,
    calculateTimeAndFreqStats: false,
};

// Often we want busy time/pass time calculations as well as production/process
// time calculations. This also allows us to compute typical relative kpis like
// relative and inverse relative process time.
export const productionTimeOnlyCalcOptions = {
    calculateTimeAndFreqStats: true,
    calculateProductionStats: true,
};

// To compute throughput times, we need output statistics, which are included
// here as well. As output rates and some other kpis are also computed relative
// to busy/process times, we also calculate those here.
export const timeAndThroughputCalcOptions = {
    ...productionTimeOnlyCalcOptions,
    calculateOutputStats: true,
    calculateUnknownStats: true,
};

// To compute all relevant time components we need to add them to true.
// This may be very time consuming and should only be chosen when really necessary.
export const allTimeOptions = {
    ...productionTimeOnlyCalcOptions,
    calculateFailureStats: true,
    calculateSetupStats: true,
    calculateInterruptionStats: true,
    calculatePassChangeStats: true,
    calculateUnknownStats: true,
    calculateBusyStats: true,
};

export type Variant = {
    id: string;
    caseFrequencyStatistics?: Stats;
    caseDurationStatistics?: Stats;
    caseYieldMassStatistics?: Stats;
    caseYieldLengthStatistics?: Stats;
    caseYieldCountStatistics?: Stats;
    caseYieldRateMassStatistics?: Stats;
    caseYieldRateLengthStatistics?: Stats;
    caseYieldRateCountStatistics?: Stats;
    count: number;

    [key: string]: Stats | string | number | undefined;
};

export type ApiVariant = {
    activities: string[];
    count: number,
    meanDuration: number
};

export type PreviewStatistics = {
    nEvents: number,
    nCases: number,
    startTime?: Date,
    endTime?: Date,
    minCaseDuration?: number,
    maxCaseDuration?: number
}

export type Histogram = {
    bins: number[],
    counts: number[]
}

export type TimeHistogram = {
    bins: string[],
    startTimeCounts: number[],
    endTimeCounts: number[]
}

export type Eventlog = {
    cases: Case[]
}

export type Case = {
    id: string,
    events: EventLogEntry[]
}

export type EventLogEntry = {
    startTime?: Date;
    endTime?: Date;
    activity?: string;
    resource?: string;
    operation?: string;
    machine?: string;
    machineType?: string;
    passId?: string;
    location?: string;
    product?: string;
    isFailure?: string;
    isSetup?: string;
    isInterruption?: string;
    isPassChange?: string;
    isLink?: string;
    role?: string;
    output?: string;
    scrap?: string;
    attributes?: { [name: string]: any };
}

export enum AggTypes {
    Mean = "mean",
    Median = "median",
    Min = "min",
    Max = "max",
    Sum = "sum",
    Std = "std",
    Total = "total",
    P25 = "p25",
    P75 = "p75",
}

export type SupplyChainNodeSchema = ProductCaseAggregationStatistics & BomNode

export type SupplyChainGraphSchema = {
    nodes: SupplyChainNodeSchema[];
    edges: Edge[];
}


export type PerTimeperiodNodeSchema = {
    nodeId: string;
    timeperiods: (NodeStats & {
        role?: NodeRoles;
        score?: number;
        objects?: NodeObject[];
        timeperiodStartTime: string;
        timeperiodEndTime: string;
    })[];
}

export type PerTimeperiodEdgeSchema = {
    timeStatistics: Stats;
    frequencyStatistics: Stats;
    yieldMassStatistics: Stats;
    yieldLengthStatistics: Stats;
    yieldCountStatistics: Stats;
    kpis: {
        yieldRateMass: Stats;
        yieldRateLength: Stats;
        yieldRateCount: Stats;
        averageYieldStockMass: Stats;
        averageYieldStockLength: Stats;
        averageYieldStockCount: Stats;
        cycleTimeMass: Stats;
        cycleTimeLength: Stats;
        cycleTimeCount: Stats;
    };
    customKpis?: {
        [key: string]: {
            value?: number;
            statistics?: Stats;
            error?: string;
        }
    };
    from: string;
    to: string;
    objectType?: string;
    score?: number;
    edgeId: string[];
    timeperiods: {
        timeperiodStartTime: string;
        timeperiodEndTime: string;
        yieldStockMassStatistics: Stats;
        yieldStockLengthStatistics: Stats;
        yieldStockCountStatistics: Stats;
    }[];
}

export type PerTimeperiodNodeStatisticsSchema = {
    frequency: TimePeriodFrequencies;
    tz: string;
    nodes: PerTimeperiodNodeSchema[];
}

export type PerTimeperiodEdgeStatisticsSchema = {
    frequency: TimePeriodFrequencies;
    tz: string;
    edges: PerTimeperiodEdgeSchema[];
}

export type PerTimeperiodNodeStatisticsParams = SubTimeOptions & TraceOptions & ApiPaginationOptions & {
    frequency: TimePeriodFrequencies;
    tz?: string;
    useActivityPasses?: boolean;
    consolidatePasses?: boolean;

    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;
    calculateUnknownStats?: boolean;
    calculatePureObjectStats?: boolean;
    calculateProductionStats?: boolean;
    calculateFailureStats?: boolean;
    calculateSetupStats?: boolean;
    calculateInterruptionStats?: boolean;
    calculatePassChangeStats?: boolean;
    calculateBusyStats?: boolean;
    calculatePassStats?: boolean;
    calculateTimeAndFreqStats?: boolean;
    calculateRoles?: boolean;
    calculateScores?: boolean;
    calculateKpis?: boolean;
    calculateActivityValues?: boolean;
    calculateSetupTimes?: boolean;
    calculateNetEdgeTimes?: boolean;
    calculateNodes?: boolean;
    calculateEdges?: boolean;
    calculateGraphLogStats?: boolean;

    calculatePlanned?: boolean;
    calculateDeviations?: boolean;

    aggs?: AggTypes[];
    objectTypes?: string[];

    nodes: string[];
}



export type PerTimeperiodFrequencyStatisticsParams = {
    frequency?: TimePeriodFrequencies;
    tz?: string;
    useActivityPasses?: boolean;
    consolidatePasses?: boolean;

    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;
    calculateUnknownStats?: boolean;
    calculatePureObjectStats?: boolean;
    calculateRoles?: boolean;
    calculateNodes?: boolean;
    calculateEdges?: boolean;
    calculateKpis?: boolean;
    calculateGraphLogStats?: boolean;
    calculateActivityValues?: boolean;
    calculateScores?: boolean;
    calculateSetupTimes?: boolean;

    calculatePlanned?: boolean;
    calculateDeviations?: boolean;

    aggs?: AggTypes[];
    objectTypes?: string[];

}

export type PerTimeperiodEdgeStatisticsParams = SubTimeOptions & TraceOptions & ApiPaginationOptions & PerTimeperiodFrequencyStatisticsParams & {
    edges: {
        from: string;
        to: string;
        objectType?: string;
    }[];
}

export type PerTimeperiodEquipmentStatisticsParams = SubTimeOptions & TraceOptions & ApiPaginationOptions & PerTimeperiodFrequencyStatisticsParams & {
    equipment?: string[] | undefined;
}

export type PerProductCaseStatisticsClusteringParams = ApiPaginationOptions & SubTimeOptions & TraceOptions & {
    percentileCuts: number[];

    statistic: string;

    consolidatePasses?: boolean;
    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;

    aggs?: AggTypes[];
};

export type PerProductCaseStatisticsClusterSchema = {
    id: string;
    limits: {
        high: number;
        low: number;
    };
    products: {
        id: string;
        name: string;
    }[];
};

export type PerProductCaseStatisticsClusteringResponseSchema = {
    groups: PerProductCaseStatisticsClusterSchema[];
}

export enum ViewConfigurationType {
    Dashboard = "dashboard",
}

export type ViewConfigurations = {
    id?: string;
    userId: string;
    created?: Date;
    updated?: Date;
    organizationId?: string;
    name: string;
    description?: string;
    isHidden?: boolean;
    isSharedWithOrganization?: boolean,
    projectId: string;
    viewId: string;
    settings: any;
    viewType?: ViewConfigurationType;
}

export type BaseQuantityType = "mass" | "length" | "count";

export type SetupEventsParams = ApiPaginationOptions & TraceOptions & {
    machines: string[];
};

export type SetupEventsNode = {
    id: string;
    activityValues: NodeActivitySchema & {
        product: NodeActivityValueSchema;
    }
};

export type TransitionSchema = {
    from: string;
    to: string;
    eventIdFrom: string;
    eventIdTo: string;
    startTime: string;
    endTime: string;
    duration: number;
    fromProduct: string;
    toProduct: string;
    fromMachine: string;
    toMachine: string;
    fromMachineType: string;
    toMachineType: string;
    fromLocation: string;
    toLocation: string;
    object: string;
    objectType: string;
}

export type SetupEventsSchema = {
    transitionEvents: TransitionSchema[],
    nodes: SetupEventsNode[]
}

export type SetupTransitionSelectionSchema = {
    actual: TransitionSchema;
    planned?: number;
    fromProduct?: string;
    toProduct?: string;
}

export type LossReasonsParams = ApiPaginationOptions & TraceOptions & {
    machines?: string[];
    losses?: string[];
};

export enum AcceptedHeaders {
    Json = "application/json",
    Excel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}
